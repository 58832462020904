import gql from 'graphql-tag';

export const COLLECTION_BY_HANDLE_BASIC = gql`
  query CollectionByHandleBasic($handle: String!) {
    collection(handle: $handle) {
      id
      handle
      title
      collection_image: metafield(
        namespace: "images"
        key: "collection_image"
      ) {
        value
      }
      hero_image: metafield(namespace: "images", key: "hero_image") {
        value
      }
      short_description: metafield(
        namespace: "collection"
        key: "short_description"
      ) {
        value
      }
      tags: metafield(namespace: "accentuate", key: "tags") {
        value
      }
      description
    }
  }
`;

export const GET_COLLECTION_BASIC = gql`
  query CollectionBasic($id: ID!) {
    collection(id: $id) {
      id
      handle
      title
      collection_image: metafield(
        namespace: "images"
        key: "collection_image"
      ) {
        value
      }
      hero_image: metafield(namespace: "images", key: "hero_image") {
        value
      }
      short_description: metafield(
        namespace: "collection"
        key: "short_description"
      ) {
        value
      }
      description
    }
  }
`;

export const GET_COLLECTION_PAINT_BUY = gql`
  query CollectionPaintBuy($id: ID!) {
    collection(id: $id) {
      id
      handle
      title
      short_description: metafield(
        namespace: "collection"
        key: "short_description"
      ) {
        value
      }
      metafields(
        identifiers: [{ namespace: "images", key: "collection_image" }]
      ) {
        id
        key
        namespace
        value
      }
      description
      products(first: 100) {
        edges {
          node {
            id
            handle
            title
            description
            tags
            productType
            variants(first: 4) {
              edges {
                node {
                  price
                  id
                  title
                  priceV2 {
                    amount
                  }
                }
              }
            }
            images(first: 3) {
              edges {
                node {
                  id
                  altText
                  originalSrc
                }
              }
            }
          }
        }
      }
    }
  }
`;
