import {
  intersection,
  map,
  head,
  get,
  toLower,
  reject,
  isEqual,
  filter,
  includes,
  replace,
  reduce,
  trimEnd,
} from './nodash';
import kebabCase from 'lodash.kebabcase';

import { COLOR_PAINT_PRODUCT_TYPES } from './productTypes';

export const isDarkColor = (color) => {
  return (
    intersection(
      ['invert', 'dark'],
      map(
        toLower,
        map((x) => get('name', x) || x, get('tags', color))
      )
    ).length > 0
  );
};

export const getColorPath = (color, productType) => {
  return `/${productType ? `${productType}/` : ''}${get('slug', color)}`;
};

export const getColorPaintPath = (color) => {
  return `/paint/${get('slug', color)}`;
};

export const sortByArrangement = (collection, arrangement, collectionType) => {
  if (!arrangement) {
    return collection;
  }
  const colorOrder = map('color.id', get('Colors', arrangement));
  const isGroup = isEqual('ProductGroup', collectionType);
  return reject((x) => {
    const color = isGroup ? head(get('colors', x)) : x;
    return isEqual(-1, colorOrder.indexOf(color.id));
  }, collection).sort((a, b) => {
    const aColor = isGroup ? head(get('colors', a)) : a;
    const bColor = isGroup ? head(get('colors', b)) : b;
    return colorOrder.indexOf(aColor.id) - colorOrder.indexOf(bColor.id);
  });
};

export const findByTag = (colors, tag, valueKey = 'id') => {
  const matchingColors = filter((color) => {
    return includes(tag[valueKey], map(valueKey, get('tags', color)), colors);
  }, colors);
  return matchingColors;
};

export const withoutWallcoverings = (items) => {
  return items.filter(
    (x) => !(x.tags || []).map((z) => z.slug).includes('wallcoverings')
  );
};

export const getColorSlugFromProductHandle = (handle) => {
  const replaced = replace('semigloss', 'semi-gloss', handle);
  const types = map(kebabCase, COLOR_PAINT_PRODUCT_TYPES);
  const slug = reduce((mem, x) => replace(x, '', mem), replaced, types);

  return trimEnd(slug, '-');
};
