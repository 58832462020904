import { reduce, get, isEqual } from '../nodash';

const getValues = (x) => ({
  method: get('discountApplication.allocationMethod', x),
  selection: get('discountApplication.targetSelection', x),
  type: get('discountApplication.targetType', x),
});

const calculateAppliedLineItemDiscount = (lineItem) => {
  const { discountAllocations, quantity } = lineItem;
  const onExplicitLineItems = reduce(
    (mem, x) => {
      const { method, selection, type } = getValues(x);
      if (
        (isEqual('ONE', method) || isEqual('ACROSS', method)) &&
        (isEqual('ALL', selection) ||
          isEqual('EXPLICIT', selection) ||
          isEqual('ENTITLED', selection)) &&
        isEqual('LINE_ITEM', type)
      ) {
        return mem + get('allocatedAmount.amount', x)
          ? parseFloat(get('allocatedAmount.amount', x))
          : 0;
      } else {
        return mem;
      }
    },
    0,
    discountAllocations
  );

  return {
    perQuantity: onExplicitLineItems / quantity,
  };
};

export default calculateAppliedLineItemDiscount;
