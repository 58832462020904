import React from 'react';
import PropTypes from 'prop-types';
import { get } from '../../lib/nodash';
import { Box, Text } from 'grommet';

import { MADE_TO_ORDER_TYPES } from '../../lib/shopConstants';
import { isPaint, isPeelAndStick, isWallcovering } from '../../lib/product';

export const isMadeToOrder = (product, variant) => {
  return get('currentlyNotInStock', variant);
};
export const isBackordered = (product, variant) =>
  MADE_TO_ORDER_TYPES.indexOf(get('productType', product)) > -1
    ? !isMadeToOrder(product, variant)
    : true;

export const getDisclaimerText = (product, variant, mtoDisclaimer) => {
  if (isMadeToOrder(product, variant) && isPaint(product)) {
    return get('value', mtoDisclaimer);
  }
  if (isBackordered(product, variant)) {
    return get('out_of_stock_message.value', product);
  }
};

export function WallcoveringsDisclaimer({ product, variant, shopMetafields }) {
  if (!isWallcovering(product)) {
    return null;
  }
  if (isPeelAndStick(variant)) {
    return null;
  }
  const disclaimer = shopMetafields?.wallcoverings_trad_disclaimer;
  if (!disclaimer || !disclaimer.value || disclaimer.value === '') {
    return null;
  }

  return (
    <Text size="small" color="status-critical">
      {disclaimer.value}
    </Text>
  );
}

const ProductInventoryDisclaimer = ({ product, variant, shopMetafields }) => {
  const notInStock = get('currentlyNotInStock', variant);
  const mtoDisclaimer = shopMetafields?.mto_disclaimer;

  return (
    <Box>
      {notInStock && (
        <Text color="status-critical">
          {getDisclaimerText(product, variant, mtoDisclaimer)}
        </Text>
      )}
      <WallcoveringsDisclaimer
        product={product}
        variant={variant}
        shopMetafields={shopMetafields}
      />
    </Box>
  );
};

ProductInventoryDisclaimer.propTypes = {
  product: PropTypes.object,
  variant: PropTypes.object,
  shopMetafields: PropTypes.object,
};

export default ProductInventoryDisclaimer;
