import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';

import ListItemCartItem from './ListItemCartItem';
import SampleCartItem from './SampleCartItem';
import { isPaint, isSample, isMemo } from '../../lib/product';
import mapOrder from '../../lib/mapOrder';

const CartItems = ({
  items,
  onRemove,
  onAdjustQuantity,
  loading,
  adjusting,
  shopMetafields,
  samplesOnly,
  samplesArrangement = [],
}) => {
  const paintItems = items.filter((x) => isPaint(x) && !isSample(x));
  const sampleItems = items
    .filter((x) => isSample(x) || isMemo(x))
    .sort(mapOrder(samplesArrangement, 'variant.id'));
  const flattenedSamples = useMemo(
    () =>
      sampleItems.reduce((mem, x) => {
        Array.from({ length: x.quantity })
          .fill(1)
          .forEach(() => {
            mem.push({ ...x, duplicate: x.quantity > 1 ? true : false });
          });
        return mem;
      }, []),
    [sampleItems]
  );
  const otherItems = items.filter(
    (x) => paintItems.indexOf(x) === -1 && sampleItems.indexOf(x) === -1
  );

  return (
    <Box pad={samplesOnly ? 'small' : 'medium'}>
      <Box gap={samplesOnly ? 'small' : 'medium'}>
        {samplesOnly
          ? flattenedSamples.map((x, i) => (
              <SampleCartItem key={`${x.id}-${i}`} {...x} />
            ))
          : sampleItems.map((x) => (
              <ListItemCartItem
                key={x.id}
                onRemove={onRemove}
                onAdjustQuantity={onAdjustQuantity}
                {...x}
                product={x.variant?.product}
                loading={loading}
                adjusting={adjusting}
                shopMetafields={shopMetafields}
              />
            ))}
        {paintItems.map((x) => (
          <ListItemCartItem
            key={x.id}
            onRemove={onRemove}
            onAdjustQuantity={onAdjustQuantity}
            {...x}
            product={x.variant?.product}
            loading={loading}
            adjusting={adjusting}
            shopMetafields={shopMetafields}
          />
        ))}
        {otherItems.map((x) => (
          <ListItemCartItem
            key={x.id}
            onRemove={onRemove}
            onAdjustQuantity={onAdjustQuantity}
            {...x}
            product={x.variant?.product}
            loading={loading}
            adjusting={adjusting}
            shopMetafields={shopMetafields}
          />
        ))}
      </Box>
    </Box>
  );
};

export default memo(CartItems);

CartItems.propTypes = {
  items: PropTypes.array,
  onRemove: PropTypes.func,
  onAdjustQuantity: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  adjusting: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  shopMetafields: PropTypes.array,
  samplesOnly: PropTypes.bool,
  samplesArrangement: PropTypes.array,
};
