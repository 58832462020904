import React, { memo, useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from '../../lib/nodash';
import { useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { Box, Text, Button } from 'grommet';
import { Close } from 'grommet-icons';
import kebabCase from 'lodash.kebabcase';

import { shopifyClient } from '../../gatsby-theme-apollo/client';
import { COLLECTION_BY_HANDLE_BASIC } from '../../queries/collectionQueries';
import SmartLink from '../SmartLink';
import Loading from '../Loading';
import { getClientForUrl, getKeyFromUrl } from '../../lib/imgix';
import { getColorSlugFromProductHandle } from '../../lib/color';
import useUpdateLineItems from './useUpdateLineItems';
import { getProductPath, hasDarkTag, isPaint } from '../../lib/product';
import { removeFromSamplesArrangement } from '../../state/cart/cartSlice';
import useMobile from '../useMobile';

const SampleCartItem = ({ variant, id, title, duplicate }) => {
  const dispatch = useDispatch();
  const [isRemoving, setRemoving] = useState(false);
  const isMobile = useMobile();

  const productType = kebabCase(get('product.productType', variant));
  const isPaintProduct = isPaint(variant.product);

  const { data } = useQuery(COLLECTION_BY_HANDLE_BASIC, {
    client: shopifyClient,
    skip: !isPaintProduct,
    variables: {
      handle: getColorSlugFromProductHandle(variant.product.handle),
    },
  });
  const paintCollection = data?.collection;

  const isWallcovering = productType === 'wallcoverings';

  const heroImage = paintCollection?.hero_image
    ? (JSON.parse(paintCollection.hero_image.value) || [])[0]?.original_src
    : undefined;
  const imgixClient = useMemo(() => getClientForUrl(heroImage), [heroImage]);
  const thumbnailImgixClient = useMemo(
    () => getClientForUrl(variant?.image?.thumb),
    [variant]
  );
  const smallHeroImage = useMemo(
    () =>
      imgixClient
        ? imgixClient.buildURL(getKeyFromUrl(heroImage), {
            h: 86,
            w: 600,
            fit: 'crop',
            'fp-x': 0.2,
            'fp-y': 0.2,
            'fp-z': 2,
            q: 100,
            crop: 'focalpoint',
          })
        : undefined,
    [heroImage, imgixClient]
  );
  const thumbnailImage = useMemo(
    () =>
      thumbnailImgixClient
        ? thumbnailImgixClient.buildURL(
            getKeyFromUrl(variant?.image?.original),
            {
              h: 65,
              w: 170,
              fit: 'crop',
              dpr: 3,
              q: 90,
              auto: ['format'],
              cs: 'srgb',
            }
          )
        : undefined,
    [heroImage, imgixClient]
  );

  const tags = paintCollection
    ? paintCollection.tags?.value
      ? paintCollection.tags?.value.split('|')
      : []
    : variant.product.tags;
  const isDark = hasDarkTag(tags);

  const { adjustLineItemQuantity } = useUpdateLineItems();
  const handleRemove = useCallback(async (e) => {
    setRemoving(true);
    e.preventDefault();
    e.stopPropagation();
    await adjustLineItemQuantity(id, variant.id, -1, false);
    setRemoving(false);
    if (!duplicate) {
      dispatch(removeFromSamplesArrangement(variant.id));
    }
  });

  return (
    <SmartLink
      to={
        paintCollection
          ? getProductPath(paintCollection, productType)
          : getProductPath(variant.product)
      }
      style={{ textDecoration: 'none' }}
      title={title}
    >
      <Box
        background={{
          image: `url(${smallHeroImage || thumbnailImage})`,
          dark: isDark,
        }}
        height={{ min: '86px' }}
        pad={isMobile ? 'medium' : 'small'}
        align="start"
        justify="start"
      >
        <Box justify="between" direction="row" fill="horizontal">
          <Box flex={true}>
            {!isWallcovering && (
              <Text size="xsmall" weight={500} style={{ letterSpacing: '1px' }}>
                {title}
              </Text>
            )}
          </Box>
          <Box
            background={isWallcovering ? 'white' : undefined}
            round={isWallcovering ? '100%' : false}
            pad={isWallcovering ? '4px' : undefined}
          >
            <Button
              plain
              onClick={handleRemove}
              icon={
                isRemoving ? (
                  <Loading />
                ) : (
                  <Close
                    color={{ dark: 'white', light: 'black' }}
                    size="16px"
                  />
                )
              }
              style={{ lineHeight: 0 }}
            />
          </Box>
        </Box>
      </Box>
    </SmartLink>
  );
};

SampleCartItem.propTypes = {
  variant: PropTypes.object,
  product: PropTypes.object,
  title: PropTypes.string,
  handle: PropTypes.string,
  id: PropTypes.string,
  duplicate: PropTypes.bool,
};

export default memo(SampleCartItem);
