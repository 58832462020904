import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Heading, Box, Text } from 'grommet';
import { get, isEqual } from '../../lib/nodash';

import SmartLink from '../SmartLink';
import InputNumberAdjust from '../Inputs/InputNumberAdjust';
import DiscountedPriceLabel from '../Product/DiscountedPriceLabel';
import ProductInventoryDisclaimer from './ProductInventoryDisclaimer';
import calculateAppliedLineItemDiscount from '../../lib/cart/calculateAppliedLineItemDiscount';
import formatCurrency from '../../lib/formatCurrency';
import { getVariantCartLimit } from '../../lib/cart/checkVariantCartLimits';
import { getProductPath } from '../../lib/product';

const ListItemCartItem = ({
  title,
  variant,
  product,
  quantity,
  id,
  onAdjustQuantity,
  loading,
  discountAllocations,
  shopMetafields,
}) => {
  const { perQuantity } = calculateAppliedLineItemDiscount({
    discountAllocations,
    quantity,
    variant,
  });
  const adjusting = useSelector((state) => state.cart.adjusting);
  const discountedPrice = get('priceV2.amount', variant) - perQuantity;
  const limit = useMemo(() => getVariantCartLimit(get('id', variant)));
  const isAdjusting = isEqual(id, adjusting);
  const isDonation = isEqual(get('product.handle', variant), 'donation');
  const productPath = getProductPath(product);

  return (
    <Box
      direction="row"
      wrap={false}
      gap="medium"
      align="center"
      margin={{ bottom: 'small' }}
      jusify="between"
    >
      <Box
        flex={{ shrink: 0 }}
        style={{ textDecoration: 'none' }}
        href={isDonation ? null : productPath}
      >
        <Box gap="6px" direction="row" align="center" justify="between">
          <Box
            as="a"
            width="65px"
            height="65px"
            background={`url(${variant.image.thumb})`}
          ></Box>
        </Box>
      </Box>
      <Box
        direction="row-responsive"
        className="cart-item-details-and-quantity"
        flex={true}
        gap="small"
      >
        <Box flex={true}>
          <SmartLink
            style={{ textDecoration: 'none' }}
            to={isDonation ? null : productPath}
          >
            <Heading level={5} margin="none" weight={500}>
              {title}
            </Heading>
            {variant?.title && variant.title !== 'Default Title' && (
              <Text>{get('title', variant)}</Text>
            )}
            <ProductInventoryDisclaimer
              product={product}
              variant={variant}
              shopMetafields={shopMetafields}
            />
          </SmartLink>
        </Box>
        <Box style={{ position: 'relative', zIndex: 0 }}>
          <InputNumberAdjust
            disabled={loading}
            quantity={quantity}
            adjusting={isAdjusting}
            max={limit}
            onChange={(qty) => {
              onAdjustQuantity(id, variant.id, qty, true);
            }}
          />
        </Box>
      </Box>
      <Box align="end" width="5rem" flex={{ shrink: 0 }}>
        <Heading level={5} margin="none" style={{ fontWeight: 400 }}>
          {formatCurrency(discountedPrice)}
        </Heading>
        {discountedPrice !== parseFloat(get('priceV2.amount', variant)) && (
          <DiscountedPriceLabel
            size=".93333rem"
            fullPrice={get('priceV2.amount', variant)}
          />
        )}
      </Box>
    </Box>
  );
};

export default memo(ListItemCartItem);

ListItemCartItem.propTypes = {
  title: PropTypes.string,
  onRemove: PropTypes.func,
  onAdjustQuantity: PropTypes.func,
  variant: PropTypes.object,
  product: PropTypes.object,
  id: PropTypes.string,
  quantity: PropTypes.number,
  loading: PropTypes.bool,
  discountAllocations: PropTypes.array,
  adjusting: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  shopMetafields: PropTypes.array,
};
